'use strict'

import SidePanel from '@campings-group/design-system/src/design/objects/sidePanel/twig/assets'
import ElementMover from 'assets/core/js/module/elementMover'

export default function (): void {
  const searchEl = document.getElementById('search-offers') as HTMLElement
  const searchSubmitEl = document.getElementById('search-offers')?.querySelector('[type="submit"]') as HTMLButtonElement
  const sidePanelEl = document.getElementById('search-filters-side-panel')

  if (!searchEl || !sidePanelEl) {
    return
  }

  const sidePanel = new SidePanel(sidePanelEl, {
    onOpen: function (sidePanel) {
      ElementMover.move(
        'search',
        searchEl,
        sidePanel.getElement().querySelector('.side-panel__content .content__block[data-content="search"]') as HTMLElement
      )
      ElementMover.move(
        'search-submit',
        searchSubmitEl,
        sidePanel.getElement().querySelector('.side-panel__bottom .actions__block[data-content="search"]') as HTMLElement
      )

      document.body.dispatchEvent(new CustomEvent('home-page.search.modal.opened'))
    },
    onClose: function () {
      // reverse order of moved elements so we don't have missing nodes when repositionning elements
      ElementMover.restore('search-submit')
      ElementMover.restore('search')

      document.body.dispatchEvent(new CustomEvent('home-page.search.modal.closed'))
    },
  })

  sidePanel.getElement().querySelector('.side-panel__content .content__block[data-content="search"]')?.classList.add('visible')
  sidePanel.getElement().querySelector('.side-panel__bottom .actions__block[data-content="search"]')?.classList.add('visible')

  document.getElementById('toggle-search')?.addEventListener('click', function (e) {
    e.preventDefault()
    sidePanel.open()
  })
}
