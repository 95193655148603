'use strict'

import SliderManager from 'assets/core/js/module/sliderManager'
import * as common from 'assets/core/js/common'

export default function (): void {
  SliderManager.initSlider('#cms-push', {
    speed: 1000,
    slidesPerView: 'auto',
    pagination: {
      el: '#cms-push-slider-pagination',
    },
    spaceBetween: 16,
    on: {
      init: function () {
        common.initLazyLoading()
      },
    },
  })

  SliderManager.initSlider('#cms-top-destination', {
    speed: 1000,
    slidesPerView: 'auto',
    navigation: {
      nextEl: '#cms-top-destination-slider-next',
      prevEl: '#cms-top-destination-slider-prev',
    },
    pagination: {
      el: '#cms-top-destination-slider-pagination',
    },
    // set loop to false as a temporary fix (setting it to true will increase CLS)
    // https://github.com/nolimits4web/swiper/issues/4076
    loop: false,
    spaceBetween: 16,
    on: {
      init: function () {
        common.initLazyLoading()
      },
    },
  })
}
